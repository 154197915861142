const API_CLIENT_PERMISSIONS = [
    // {
    //     description: "None",
    //     value: 0
    // },
    {
        description: "List and read user records",
        value: 1,
        const: "ReadUsers"
    },
    {
        description: "List and read non-personal lookup records including Locations, Incident Types, etc",
        value: 2,
        const: "ReadLookups"
    },
    {
        description: "Create a new incident",
        value: 4,
        const: "CreateIncident"
    },
    {
        description: "Add messages into an incident timeline",
        value: 8,
        const: "CreateMessage"
    },
    {
        description: "Create a new incidents on behalf of a user",
        value: 1024,
        const: "CreateIncidentOnBehalf"
    },
    {
        description: "Create an incident in their system",
        value: 2048,
        const: "OutboundCreate",
        notOnApiClients: true
    },
    {
        description: "Export an incident timeline to their system",
        value: 4096,
        const: "OutboundExport",
        notOnApiClients: true
    },
    {
        description: "Create new incidents via email",
        value: 8192,
        const: "CreateViaEmail"
    },
];

export default {
    hasPermission(grantedPermissions, permissionLevelToCheck) {
        return (grantedPermissions & permissionLevelToCheck) == permissionLevelToCheck
    },

    listApiClientPermissions() {
        return API_CLIENT_PERMISSIONS
    }
}
