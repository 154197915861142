<template>
  <div class="admin-review-integration">
    <loading-screen :is-loading="isAuthorising"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Review Partner Integration</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <organisation-details-panel></organisation-details-panel>

    <!-- Card - Basic Details -->
    <div class="card basic-details flex">
      <div class="left">
        <api-client-thumbnail
          :tenant-id="tenantId"
          size="large"
          :api-client="partner"
        ></api-client-thumbnail>
      </div>
      <div class="right">
        <h3>{{ partner.displayName }}</h3>

        {{ partner.headline }}
      </div>
    </div>
    <!-- / Card - Basic Details  -->

    <!-- Loading -->
    <div v-if="isLoadingLinkTarget" class="card loading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- More Details -->
    <div v-if="!isLoadingLinkTarget" class="integration-details">
      <a-row :gutter="20" type="flex">
        <!-- Requested Permissions -->
        <a-col :span="colSpan">
          <div class="col-inner">
            <div class="card requested-permissions">
              <h3>Requested Permissions</h3>

              <a-alert
                v-if="requestedPermissions.length == 0"
                type="error"
                message="No permissions have been requested"
              />

              <div v-if="requestedPermissions.length">
                <ul>
                  <li v-for="(permission, i) in requestedPermissions" :key="i">
                    {{ permission.description }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </a-col>
        <!-- / Requested Permissions -->

        <!-- Support information -->
        <a-col :span="colSpan">
          <div class="col-inner">
            <div class="card support-information">
              <h3>Support Information</h3>

              <div class="support-information-buttons">
                <a-button
                  v-if="partner.privacyPolicyUrl"
                  @click.prevent="openLink(partner.privacyPolicyUrl)"
                  type="default"
                  icon="link"
                  >Privacy Policy</a-button
                >
                <a-button
                  v-if="partner.supportUrl"
                  @click.prevent="openLink(partner.supportUrl)"
                  type="default"
                  icon="link"
                  >Support Website</a-button
                >
                <a-button
                  @click.prevent="openLink('mailto:' + partner.supportEmail)"
                  type="default"
                  icon="link"
                  >Support Email</a-button
                >
              </div>
            </div>
          </div>
        </a-col>
        <!-- / Support information -->
      </a-row>
    </div>
    <!-- / More Details -->

    <!-- Authentication -->
    <div v-if="canEditApiClient && !isLoadingLinkTarget && linkTarget && (linkTarget.organisationKeyName || linkTarget.secretName)" class="card authentication-form">
      <h3>Authentication</h3>

      <a-form-item v-if="linkTarget.organisationKeyName" :label="linkTarget.organisationKeyName">
        <a-input v-model="organisationKey" size="large"></a-input>
      </a-form-item>

       <a-form-item v-if="linkTarget.secretName" :label="linkTarget.secretName">
        <a-input-password v-model="secret" size="large"></a-input-password>
      </a-form-item>
    </div>
    <!-- / Authentication -->

    <!-- Authorise form -->
    <div v-if="canEditApiClient && !isLoadingLinkTarget" class="card authorise-form">
      <a-form-item label="Email Parser Name" v-if="createViaEmail">
        <a-input v-model="requestedParser" size="large"></a-input>
      </a-form-item>

      <a-popconfirm
        title="Are you sure?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="attemptAuthorise"
      >
        <a-button size="large" type="primary">Authorise</a-button>
      </a-popconfirm>
    </div>
    <!-- / Authorise form -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import ApiClientThumbnail from "../../../../Incidents/Show/Tabs/Escalate/ShareWith/ApiClientCard/ApiClientThumbnail.vue";
import organisationHelpers from "../../../../../helpers/organisations";
import LoadingScreen from "../../../../../components/LoadingScreen.vue";
import apiClientHelpers from "../../../../../helpers/api-clients";
import apiGrants from "../../../../../api/api-grants";
import apiClients from "../../../../../api/api-clients";
import OrganisationDetailsPanel from '../OrganisationDetailsPanel.vue';

export default {
  name: "ReviewIntegration",
  data() {
    return {
      isAuthorising: false,

      requestedParser: null,
      organisationKey: null,
      secret: null,

      isLoadingLinkTarget: false,
      linkTarget: null,
    };
  },
  created() {
    if (this.partner && this.partner.requestedParser) {
      this.requestedParser = this.partner.requestedParser;
    }
    if (this.partner.linkTargetId) {
      this.loadLinkTarget();
    }
  },
  watch: {
    partner(newVal) {
      if (newVal && newVal.requestedParser) {
        this.requestedParser = this.partner.requestedParser;
      }
    },
  },
  components: { ApiClientThumbnail, LoadingScreen, OrganisationDetailsPanel },
  computed: {
    ...mapGetters("missionControl", {
      organisations: "organisations",
    }),

    ...mapGetters("adminApiClients", {
      partnersToReview: "partnersToReview",
    }),

    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      myPresenceId: "myPresenceId",
    }),

    ...mapGetters("permissions", {
      canEditApiClient: "canEditApiClient"
    }),

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },

    partner() {
      return _.find(this.partnersToReview, (partner) => {
        return partner.id == this.$route.params.id;
      });
    },

    tenantId() {
      if (!this.partner) {
        return null;
      }

      let organisation = _.find(this.organisations, (org) => {
        return org.ownerId == this.partner.id;
      });

      if (organisation) {
        return organisationHelpers.getOrganisationTenantId(organisation);
      }

      return this.partner.id;
    },

    requestedPermissions() {
      let granted = [];
      _.each(apiClientHelpers.listApiClientPermissions(), (permissionLevel) => {
        if (
          apiClientHelpers.hasPermission(
            this.partner.requestedPermissions,
            permissionLevel.value
          )
        ) {
          granted.push(permissionLevel);
        }
      });
      return granted;
    },

    createViaEmail() {
      let hasPermission = false;
      _.each(this.requestedPermissions, (rp) => {
        if (rp.value && rp.value == 8192) { // create via email
          hasPermission = true;
        }
      });
      return hasPermission;
    },
  },
  methods: {
    goBack() {
      this.$router.push("/admin/integration/partners/create");
    },

    openLink(link) {
      window.open(link);
    },

    validate() {
      let validationErrors = [];

      if (this.createViaEmail && !this.requestedParser.trim().length) {
        validationErrors.push(["Please provide an email parser"]);
      }

      if (
        this.linkTarget
        && this.linkTarget.organisationKeyName
        && (!this.organisationKey || !this.organisationKey.trim().length)
      ) {
        validationErrors.push("Please provide an authentication " + this.linkTarget.organisationKeyName);
      }

        if (
        this.linkTarget
        && this.linkTarget.secretName
        && (!this.secret || !this.secret.trim().length)
      ) {
        validationErrors.push("Please provide an authentication " + this.linkTarget.secretName);
      }

      return validationErrors;
    },

    attemptAuthorise() {
      let vm = this;
      vm.isAuthorising = true;

      let validationErrors = vm.validate();
      if (validationErrors.length) {
        vm.$message.error(validationErrors[0]);
        vm.isAuthorising = false;
        return false;
      }

      let params = {
        ownerId: this.selectedOrganisation.id,
        apiClientId: this.partner.id,
        grantedPermissions: this.partner.requestedPermissions,
        grantedBy: this.myPresenceId,
        parser: this.requestedParser,
      };

      apiGrants
        .addApiGrant(this.tenantId, params)
        .then(() => {
          let needsToProvideSecret = (
            this.linkTarget
            && (this.linkTarget.organisationKeyName || this.linkTarget.secretName)
          );

          if (needsToProvideSecret) {
              apiGrants.setApiGrantSecret(vm.tenantId, vm.selectedOrganisation.id, vm.partner.id, {
                organisationKey: vm.organisationKey,
                secret: vm.secret
              }).then(() => {
                vm.$message.success("Partner authorised successfully");
                vm.$router.push("/admin/integration/partners");
              }).catch(e => {
                console.log(e);
                vm.$message.error('Error setting API grant secret');
                vm.$message.success("Partner authorised successfully");
                vm.$router.push("/admin/integration/partners");
              });
          } else {
            vm.$message.success("Partner authorised successfully");
            vm.$router.push("/admin/integration/partners");
          }
        })
        .catch((e) => {
          console.log(e);
          vm.isAuthorising = false;
          vm.$message.error("Error authorising partner");
        });
    },

    async loadLinkTarget() {
      let vm = this;
      vm.isLoadingLinkTarget = true;

      try {
        vm.linkTarget = await apiClients
          .getApiClientLinkTarget(vm.partner.linkTargetId)
          .then((r) => r.data);
      } catch (e) {
        console.log(e);
      }

      vm.isLoadingLinkTarget = false;
    },
  },
};
</script>

<style lang="scss">
.admin-review-integration {
  padding-bottom: 25px;

  .button-margin-left {
    margin-left: 10px;
  }

  .card {
    background: #fff;
    padding: 25px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 20px;

    &.loading {
      text-align: center;
    }

    &.flex {
      display: flex;
    }

    .left {
      flex-shrink: 1;
      padding-right: 30px;
    }

    .right {
      flex-grow: 1;
    }

    .ant-avatar-lg {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }

    h3 {
      margin-bottom: 15px;
    }


    &.basic-details {
      h3 {
        margin-bottom: 9px;
      }
    }

    &.requested-permissions {
      ul {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &.support-information {
      .support-information-buttons {
        padding-top: 10px;
        .ant-btn {
          margin-right: 15px;
        }
      }
    }
  }

  .integration-details {
    .col-inner {
      padding-bottom: 20px;
    }

    .card,
    .col-inner {
      height: 100%;
    }
  }
}
</style>