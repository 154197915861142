<template>
  <div class="organisation-details-panel">
    <a-alert message="Your Organisation" type="info" show-icon>
      <template slot="description" v-if="selectedOrganisation">
        <div class="detail">
          <span>Organisation Name:</span> {{ selectedOrganisation.displayName }}
        </div>
        <div class="detail">
          <span>Organisation ID:</span> {{ selectedOrganisation.id }}
        </div>
      </template>
    </a-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('admin', {
      selectedOrganisation: 'selectedOrganisation'
    })
  }
}
</script>

<style scoped lang="scss">
.organisation-details-panel {
  margin-bottom: 20px;
}
</style>