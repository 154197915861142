<template>
  <div class="api-client-thumbnail-inline" :class="{'larger-for-editing': largerForEditing}">
    <a-avatar
      :size="size ? size : 'default'"
      v-if="thumbnail"
      :src="thumbnail.url"
    />
    <a-avatar :size="size ? size : 'default'" icon="loading" v-else />
  </div>
</template>

<script>
import apiClients from "../../../../../../../api/api-clients";
export default {
  props: ["tenantId", "apiClient", "size", "largerForEditing"],
  data() {
    return {
      isLoadingThumbnail: false,
      thumbnail: null,
    };
  },
  created() {
    this.getThumbnailUrl();
  },
  methods: {
    getThumbnailUrl() {
      let vm = this;
      vm.isLoadingThumbnail = true;
      apiClients
        .getApiClientThumbProtectedUrl(this.tenantId, this.apiClient.id)
        .then((r) => {
          vm.isLoadingThumbnail = false;
          vm.thumbnail = r.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss">
.api-client-thumbnail-inline {
  &.larger-for-editing {
    .ant-avatar {
      width: 100px !important;
      height: 100px !important;
    }
  }
}
</style>